<template>
  <div>
    <b-modal
      id="modal-input"
      size="lg"
      centered
      title="Tambah Data Pelamar Kerja"
      header-bg-variant="primary"
      header-text-variant="light"
    >
      <b-form>
        <b-form-group label="Posisi Yang Dilamar" label-cols-md="3">
          <multiselect
            :state="checkIfValid('lokerId')"
            v-model="$v.data.lokerId.$model"
            :disabled="false"
            :options="loker"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            placeholder="-- Pilih Posisi --"
            label="posisiDilamar"
            track-by="masterPosisiId"
            @input="itikiwir(data.lokerId)"
          ></multiselect>
        </b-form-group>
        <center>
          <p v-if="dataLoker">
            umur minimal {{ dataLoker.umurMinimal }} -
            {{ dataLoker.umurMaksimal }}
          </p>
        </center>

        <b-form-group label="Nama Lengkap" label-cols-md="3">
          <b-form-input
            type="text"
            :state="checkIfValid('namaPengirim')"
            v-model="$v.data.namaPengirim.$model"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Nomor KTP" label-cols-md="3">
          <b-form-input
            type="number"
            :state="checkIfValid('nomorKTPPengirim')"
            v-model="$v.data.nomorKTPPengirim.$model"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Nomor Telepon" label-cols-md="3">
          <b-form-input
            type="number"
            :state="checkIfValid('nomorTeleponPengirim')"
            v-model="$v.data.nomorTeleponPengirim.$model"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Tanggal Lahir" label-cols-md="3">
          <date-picker
            style="width: 100%"
            :state="checkIfValid('tanggalLahirPengirim')"
            v-model="$v.data.tanggalLahirPengirim.$model"
            @change="cekUmur(data.tanggalLahirPengirim)"
          ></date-picker>
        </b-form-group>
        <p v-if="ngecek" style="color: red">maaf umur tidak sesuai</p>
        <b-form-group label="Alamat" label-cols-md="3">
          <b-form-textarea
            type="text"
            :state="checkIfValid('alamatPengirim')"
            v-model="$v.data.alamatPengirim.$model"
          ></b-form-textarea>
        </b-form-group>

        <b-form-group label="Email" label-cols-md="3">
          <b-form-input
            type="text"
            :state="checkIfValid('emailPengirim')"
            v-model="$v.data.emailPengirim.$model"
          ></b-form-input>
        </b-form-group>

        <!-- <b-form-group label="Divisi Yang Dilamar" label-cols-md="3">
          <multiselect
            :disabled="false"
            :options="divisi"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            placeholder="-- Pilih Divisi --"
          ></multiselect>
        </b-form-group> -->

        <b-form-group
          label-cols-md="3"
          v-for="(item, idx) in data.files"
          :key="idx"
        >
          <template v-slot:label> {{ item.namaKebutuhan }} </template>
          <b-row>
            <b-col
              ><b-button
                variant="primary"
                class="mr-1"
                @click="selectFile(), (index = idx)"
                >Upload File</b-button
              ></b-col
            >
            <b-col>
              <b-form-checkbox
                v-model="item.statusUpload"
                disabled
                value="1"
                unchecked-value="0"
              >
              </b-form-checkbox
            ></b-col>
          </b-row>
        </b-form-group>

        <b-form-file
          class="inputan"
          ref="file1"
          type="file"
          @input="handleFile()"
        ></b-form-file>
      </b-form>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-input')">
          Batal
        </b-button>
        <b-button
          variant="primary"
          :disabled="busy || !isValid || ngecek"
          @click="simpan()"
        >
          {{ button }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import { validationMixin } from "vuelidate";
import {
  required,
  email,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
export default {
  name: "modalInput",
  props: ["loker"],
  components: {
    Multiselect,
    DatePicker,
  },

  data() {
    return {
      data: {
        namaPengirim: "",
        alamatPengirim: "",
        nomorKTPPengirim: "",
        nomorTeleponPengirim: "",
        tanggalLahirPengirim: "",
        emailPengirim: "",
        lokerId: "",
        statusPostLoker: 0,
        files: [],
      },
      ngecek: false,
      busy: false,
      button: "Simpan",
      src1: "",
      dataLoker: "",
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.data, null, 4);
    },
    isValid() {
      return !this.$v.data.$invalid;
    },
    isDirty() {
      return this.$v.data.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    data: {
      namaPengirim: {
        required,
      },
      alamatPengirim: {
        required,
      },
      nomorKTPPengirim: {
        required,
        minLength: minLength(16),
        maxLength: maxLength(16),
      },
      nomorTeleponPengirim: {
        required,
      },
      tanggalLahirPengirim: {
        required,
      },
      emailPengirim: {
        required,
        email,
      },
      lokerId: {
        required,
      },
    },
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.data[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    itikiwir(x) {
      console.log(x, "ini x");
      this.dataLoker = x;
      this.getKebutuhan();
    },
    cekUmur(x) {
      let vm = this;
      if (x == "") {
        vm.busy = false;
      } else {
        let umur = moment().format("YYYY") - moment(x).format("YYYY");
        if (
          umur > vm.dataLoker.umurMaksimal ||
          umur < vm.dataLoker.umurMinimal
        ) {
          vm.ngecek = true;
        } else {
          vm.ngecek = false;
        }
      }
    },
    async getKebutuhan() {
      let vm = this;
      let id = this.dataLoker.lokerId;
      let listKebutuhan = await axios.get(
        ipBackend + "loker/listByKebutuhanLokerId/" + id
      );
      vm.data.files = listKebutuhan.data.data.map((item, idx) => {
        return {
          ...item,
          file1: "",
          lokerId: item.idLoker,
          field: `file${idx + 1}`,
          statusUpload: 0,
        };
      });
    },
    selectFile() {
      let fileInputElement = this.$refs.file1;
      fileInputElement.$refs.input.click();
    },
    handleFile() {
      this.data.files[this.index].file1 = this.$refs.file1.files[0];

      console.log(this.$refs.file1.files[0].name, "iki");
      if (this.data.files[this.index].file1 != this.file1) {
        this.data.files[this.index].statusUpload = 1;
      }
      this.file1 = this.$refs.file1.files[0];
      // this.$refs.file1.files = []
      console.log(this.$refs.file1.files[0].name, "iki2");
    },
    simpan() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      vm.data.lokerId = vm.data.lokerId.lokerId;
      let formData = new FormData();
      if (vm.data.files.length > 0) {
        for (let i = 0; i < vm.data.files.length; i++) {
          formData.append(`file${i + 1}`, vm.data.files[i].file1);
        }
      }
      formData.append("namaPengirim", vm.data.namaPengirim);
      formData.append("nomorKTPPengirim", vm.data.nomorKTPPengirim);
      formData.append("nomorTeleponPengirim", vm.data.nomorTeleponPengirim);
      formData.append("tanggalLahirPengirim", vm.data.tanggalLahirPengirim);
      formData.append("alamatPengirim", vm.data.alamatPengirim);
      formData.append("emailPengirim", vm.data.emailPengirim);
      formData.append("lokerId", vm.data.lokerId);
      formData.append("statusPostLoker", vm.data.statusPostLoker);
      formData.append("bulk", JSON.stringify(vm.data.files));
      axios
        .post(ipBackend + "postLoker/register2", formData)
        .then((res) => {
          console.log(res);
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL MENDAFTARKAN PELAMAR",
              showing: true,
            });
            vm.data.namaPengirim = "";
            vm.data.alamatPengirim = "";
            vm.data.emailPengirim = "";
            vm.data.tanggalLahirPengirim = "";
            vm.data.nomorKTPPengirim = "";
            vm.data.nomorTeleponPengirim = "";
            vm.data.files = [];
            vm.$v.$reset();
            this.$bvModal.hide("modal-input");
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: _.toUpper(res.data.message),
              showing: true,
            });
            this.$bvModal.hide("modal-input");
          }
        })
        .catch((err) => {
          console.log(err, "ini err");
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alertFromChild", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
          this.$bvModal.hide("modal-input");
        });
    },
  },
};
</script>
<style scoped>
.inputan {
  visibility: hidden;
}
</style>
