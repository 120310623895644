<template>
  <div>
    <b-modal
      id="modal-detail-new"
      size="lg"
      centered
      title="Detail Pelamar"
      header-bg-variant="info"
      header-text-variant="light"
      scrollable
    >
      <b-row>
        <b-col cols="12" md="6" lg="6">
          <!-- <pdf v-for="i in numPages" :key="i" :src="src" :page="i"></pdf> -->
          <pdf :src="src"></pdf>
        </b-col>

        <b-col cols="5" md="6" lg="6">
          <b-table-simple small borderless>
            <b-tbody>
              <b-tr>
                <b-td style="width: 30%">Nama</b-td>
                <b-td style="width: 5%">:</b-td>
                <b-td>{{ data.namaPengirim }}</b-td>
              </b-tr>

              <b-tr>
                <b-td>Alamat</b-td>
                <b-td>:</b-td>
                <b-td>{{ data.alamatPengirim }}</b-td>
              </b-tr>

              <b-tr>
                <b-td>Email</b-td>
                <b-td>:</b-td>
                <b-td>{{ data.emailPengirim }}</b-td>
              </b-tr>

              <b-tr>
                <b-td>Posisi Yang Dilamar</b-td>
                <b-td>:</b-td>
                <b-td>{{ data.posisiDilamar }}</b-td>
              </b-tr>
            </b-tbody>
            <b-tbody v-for="item in listFile" :key="item.kebutuhanPelamarId">
              <b-tr>
                <b-td>{{ item.namaKebutuhan }}</b-td>
                <b-td>:</b-td>
                <b-td
                  ><b-button
                    size="sm"
                    variant="light"
                    @click="view(item.fileKebutuhan)"
                    >Lihat File</b-button
                  ></b-td
                >
              </b-tr>
            </b-tbody>
            <b-tbody>
              <b-tr>
                <b-td>Notifikasi</b-td>
                <b-td>:</b-td>
                <b-td
                  ><b-form-checkbox
                    v-model="notif"
                    value="1"
                    unchecked-value="0"
                    >Kirim Email notifikasi</b-form-checkbox
                  ></b-td
                >
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
      <template #modal-footer>
        <b-button variant="primary" @click="$bvModal.show('modal-acc')">
          Terima
        </b-button>
        <b-button
          variant="secondary"
          @click="$bvModal.hide('modal-detail-new')"
        >
          Batal
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="modal-acc"
      size="md"
      centered
      title="Perhatian .... !!!"
      header-bg-variant="success"
      header-text-variant="light"
    >
      <p>Apakah anda yakin akan menerima pelamar ini ?</p>

      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-acc')">
          Tidak
        </b-button>
        <b-button variant="primary" @click="simpan()"> Ya </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import pdf from "vue-pdf";
import { ipBackend } from "@/ipBackend";

export default {
  name: "modalDetail",
  props: ["data", "detail"],
  components: {
    pdf,
  },
  data() {
    return {
      src: "",
      numPages: undefined,
      notif: "1",
      listFile: [],
    };
  },

  watch: {
    detail: function (newVal, oldVal) {
      if (oldVal != newVal) {
        console.log(this.data, "ini data");
        this.getFile();
      }
    },
  },
  // mounted() {
  // 	this.src.promise.then(pdf => {
  // 		this.numPages = pdf.numPages;
  // 	});
  // }
  methods: {
    view(x) {
      window.open(`${ipBackend}asset/pdf/${x}`);
    },
    async getFile() {
      let id = this.data.postLokerId;
      let fileKebutuhan = await axios.get(
        ipBackend + "postLoker/listKebutuhanByLokerId/" + id
      );
      this.listFile = fileKebutuhan.data.data;
      console.log(this.listFile, "ini kebutuhan file");
    },
    simpan() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      vm.data.id = vm.data.postLokerId;
      vm.data.statusPostLoker = 1;
      vm.data.notif = vm.notif;
      axios
        .post(ipBackend + "postLoker/update", vm.data)
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL MENERIMA PELAMAR",
              showing: true,
            });
            this.$bvModal.hide("modal-acc");
            this.$bvModal.hide("modal-detail-new");
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: _.toUpper(res.data.message),
              showing: true,
            });
          }
        })
        .catch((err) => {
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alertFromChild", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
        });
    },
  },
};
</script>
