<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-card header-tag="header" header-bg-variant="dark">
            <template #header>
              <h5 class="mb-0" style="color: #fff">
                <strong>{{ $store.state.table.pelamar }}</strong>
              </h5>
            </template>
            <b-tabs content-class="mt-3" align="center">
                <b-tab active title="Pelamar">
                    <pelamar @change="change = !change" />
                </b-tab>
                <b-tab title="Pelamar Ditolak">
                    <reject @change="change = !change"  />
                </b-tab>
            </b-tabs>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend.js";
import pelamar from "./pelamarKerja.vue"
import reject from "./pelamarKerjaDitolak.vue"


export default {
  components: {
    pelamar,
    reject
  },
  name: "pelamarKerja",
  data() {
    return {
      change: false
    };
  },
  computed: {
  },
  mounted() {
   
  },
  methods: {
    
    
  },
};
</script>
